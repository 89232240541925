import React, {useEffect, useState} from "react";
import {Routes, Route, useLocation} from 'react-router-dom';
import {CatalogPage} from "./pages/catalog";
import {ShopItemInnerPage} from "./pages/shop_item_inner";
import styles from "./routes.module.scss"
import {TransitionGroup, CSSTransition} from "react-transition-group";

export const RoutesMap: React.FC = () => {
    const location = useLocation()
    const animateClass = () => {
        return !(location.pathname.includes('/shop-item/') || location.pathname.includes('checkout')) ? "slide-right" : "slide"
    }
    return (
        <TransitionGroup className={styles.routerWrapper}>
            <CSSTransition key={location.pathname} classNames={animateClass()} timeout={2000}>
                <Routes location={location}>
                    <Route path={'/'} element={<CatalogPage/>}/>
                    <Route path={'/cancelled'} element={<CatalogPage/>}/>
                    <Route path={'/stock'} element={<CatalogPage/>}/>
                    <Route path={'/shop-item/:slug'} element={<ShopItemInnerPage/>}/>
                    <Route path={'/checkout'} element={<ShopItemInnerPage/>}/>
                    <Route path={'/success/:stripe_id'} element={<CatalogPage/>}/>
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    )
}
