import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IShopItem {
    title: string
    preview_img: any
    price: string,
    price_number: number,
    product_id: string,
    stripe_id: string
}

interface ICart {
    items: IShopItem[]
}

const initialState: ICart = {
    items: []
}

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addItem: (state, action: PayloadAction<IShopItem>) => {
            state.items.push(action.payload)
        },
        removeItem: (state, action: PayloadAction<IShopItem>) => {
            let arr = [...state.items]

            const index = arr.findIndex(item => item.title === action.payload.title)
            arr.splice(index, 1)
            state.items = arr
        }
    }
})

export const {addItem, removeItem} = cartSlice.actions

export default cartSlice.reducer