import React from "react";
import styles from "./index.module.scss"
import classNames from "classnames";

interface IFooter {
    columnStyle?: boolean
}

export const Footer: React.FC<IFooter> = (props) => {
    const {columnStyle} = props
    return (
        <div className={classNames(styles.footer, {
            [styles.footer_column]: columnStyle
        })}>
            <div className={classNames(styles.footer__item, styles.footer__item_mobile)}>© 2023 biuro_All rights reserved</div>
            <div className={styles.footer__item}>faq</div>
            <div className={classNames(styles.footer__item, styles.footer__item_desktop)}>contact</div>
            <div className={classNames(styles.footer__item, styles.footer__item_desktop)}>© 2023 biuro</div>
            <div className={classNames(styles.footer__item, styles.footer__item_desktop)}>instagram</div>
            <div className={styles.footer__item}>terms & conditions</div>
        </div>
    )
}