import * as React from "react"

export function CrossIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             width="8.377"
             height="8.707"
             viewBox="0 0 8.377 8.707"
             {...props}
        >
            <g id="X" transform="translate(-2241.138 -577.155)">
                <line id="Line_1" data-name="Line 1" x2="7.654" y2="8.017" transform="translate(2241.5 577.5)"
                      fill="none" stroke="#0020e4" strokeWidth="1"/>
                <line id="Line_2" data-name="Line 2" x1="7.654" y2="8.017" transform="translate(2241.5 577.5)"
                      fill="none" stroke="#0020e4" strokeWidth="1"/>
            </g>
        </svg>

    )
}