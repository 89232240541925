import React from "react";
import styles from "./index.module.scss"

interface IInput{
    onChange: (value: string) => void
    label: string
    value: string
    placeholder: string
}

export const Input: React.FC<IInput> = (props) => {
    const {label, value, placeholder, onChange} = props

    return (
        <div className={styles.input}>
          <div className={styles.input__label}> {label}</div>
           <input onChange={(value) => onChange(value.target.value)}
                  value={value}
                  placeholder={placeholder}
           />
        </div>
    )
}