import networkService from "../../networkService";

export const getAllShopItems = () => {
    return new Promise((resolve, reject) => {
        networkService.get('shop-items/all')
            .then((r) => resolve(r))
            .catch((e) => reject(e))
    })
}


export const getItem = (id: string) => {
    return new Promise((resolve, reject) => {
        networkService.get('shop-items/item/' + id)
            .then((r) => resolve(r))
            .catch((e) => reject(e))
    })
}
