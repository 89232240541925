// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".routes_routerWrapper__3uZhS {\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/routes.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF","sourcesContent":[".routerWrapper{\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"routerWrapper": "routes_routerWrapper__3uZhS"
};
export default ___CSS_LOADER_EXPORT___;
