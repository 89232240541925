import React from "react";
import styles from "./index.module.scss"

interface IShopItemCarousel {
    items: any
}

export const ShopItemCarousel: React.FC<IShopItemCarousel> = (props) => {
    const {items} = props

    const listItems = items.map((item, index) =>

        <div className={styles.carousel__item}>
            {item.type === 'image' &&
                <img src={process.env.REACT_APP_ENV_BACKEND_DOMAIN + item.image} alt={item.name}/>
            }
            {
                item.type === '3D-model' &&
                // @ts-ignore
                <model-viewer camera-controls touch-action="pan-y" alt="A 3D model of a sphere"
                              src={process.env.REACT_APP_ENV_BACKEND_DOMAIN + item.image}/>
            }
        </div>
    );


    return (
        <div className={styles.carousel}>
            {listItems}
        </div>
    )
}