import React, {useEffect, useState} from "react";
import {Logo} from "../../components/logo";
import {Footer} from "../../components/footer";
import styles from "./index.module.scss"
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {BuyStage, ShoppingCart} from "../../components/shopping_cart";
import classNames from "classnames";
import {ShopItemDetails} from "../../components/shop_item_details";
import {CheckoutForm} from "../../components/checkout_form";
import {getItem} from "../../services/api/shop_items";
import {loadStripe} from "@stripe/stripe-js";
import {createOrder, initCheckoutSession} from "../../services/api/orders";
import {Loader} from "../../components/loader";
import arrowImg from "../../components/shopping_cart/assets/down-chevron.png";
import {useSelector} from "react-redux";
import {ArrowIcon} from "../../components/icons/ArrowIcon";

export const ShopItemInnerPage: React.FC = () => {
    const namesReducer = useSelector((state: any) => state.cart)
    const {items} = namesReducer
    const [cartIsOpen, setCartIsOpen] = useState(false)
    const [isCheckoutOpen, setIsCheckoutOpen] = useState(false)
    const [shipPrice, setShipPrice] = useState(0)
    const [currentBuyStage, setCurrentBuyStage] = useState(BuyStage.default)
    const [shopItem, setShopItem] = useState<any>(null)
    const [formItem, setFormItem] = useState<any>(null)
    const [itemsIds, setItemsIds] = useState([])
    const [amount, setAmount] = useState<any>(null)
    const [isLoad, setIsLoad] = useState(true)
    const [isMobileCheckoutOpen, setIsMobileCheckoutOpen] = useState(false)
    const [isMobileCartOpen, setIsMobileCartOpen] = useState(false)
    const [cartIsShow, setCartIsShow] = useState(false)
    const {slug} = useParams();

    const navigate = useNavigate()
    const location = useLocation();

    const handleCartSwitch = () => {
        if (location.pathname == '/checkout') {
            navigate('/stock')
        } else {
            if (isCheckoutOpen) {
                setCartIsOpen(true)
                setIsCheckoutOpen(false)
            } else {
                setCartIsOpen(!cartIsOpen)
            }
        }
    }

    const handleCheckout = () => {
        setIsCheckoutOpen(true)
    }

    const handleHideCart = () => {
        setCartIsOpen(false)
        setTimeout(() => {
            setCartIsShow(false)
        }, 800)
    }

    const handleShowCart = () => {
        setCartIsShow(true)
        setCartIsOpen(true)
    }

    const initStripe = async () => {
        return await loadStripe('pk_live_51MUCR0IiRcF4A8O5jg8nXCr6MugmtG5shkyu932HdqlO05JKDw6PNYaSlzyN7JU7qwikNfw0XsXfNwA7Y8obMqaX00M9UjWyzI')
    }

    const handlePay = () => {
        // initStripe()
        //     .then((stripe) => {
        //         initCheckoutSession(itemsIds)
        //             .then((r: any) => {
        //                 const item = Object.assign({amount: amount, products_ids: itemsIds}, formItem)
        //                 createOrder(item)
        //                     .then(() => stripe.redirectToCheckout({sessionId: r.sessionId}))
        //                     .catch((e) => console.log(e))
        //             })
        //             .catch((e) => console.log(e))
        //     })

        initStripe()
            .then((stripe) => {
                const item = Object.assign({amount: amount, products_ids: itemsIds}, formItem)
                createOrder(item)
                    .then((r: any) => {
                        initCheckoutSession(itemsIds, r.data.id)
                            .then((r_session: any) => stripe.redirectToCheckout({sessionId: r_session.sessionId}))
                            .catch((e) => console.log(e))
                    })
            })
    }


    useEffect(() => {
        if (isCheckoutOpen) {
            setCurrentBuyStage(BuyStage.checkout)
        }
    }, [isCheckoutOpen])

    useEffect(() => {
        if (!shopItem && location.pathname !== '/checkout') {
            getItem(slug)
                .then((r: any) => {
                    setShopItem(r.item)
                })
                .catch((e) => console.log(e))
        }
    }, [shopItem])


    useEffect(() => {
        if (location.pathname == '/checkout') {
            setCartIsOpen(true)
            setIsCheckoutOpen(true)
            setCurrentBuyStage(BuyStage.checkout)
        }
    }, [location.pathname])


    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoad(false)
        }, 10000);
        return () => clearTimeout(timer);
    })

    useEffect(() => {
        if (items.length < 1) {
            setIsMobileCheckoutOpen(false)
            setIsMobileCartOpen(false)
        }
    }, [items])

    return (
        <div className={styles.page}>
            <div className={styles.hideMobile}>
                <Logo onClick={() => {
                    navigate('/stock', {state: {prevPath: 'shop-item'}})
                }}/>
            </div>
            <div className={classNames(styles.wrapper, {
                [styles.wrapper_checkout]: isCheckoutOpen
            })}>
                <div onClick={() => {
                    navigate('/stock')
                }} className={styles.showDesk}>
                    <div className={styles.backButton}>
                             <ArrowIcon width={18} height={18}/>
                    </div>
                </div>
                <div className={classNames(styles.wrapper__left, {
                    [styles.wrapper__left_checkout]: isCheckoutOpen,
                })}>
                    {shopItem &&
                        <ShopItemDetails item={shopItem}
                                         animateCheckout={isCheckoutOpen}
                                         animateCart={cartIsOpen}
                        />
                    }
                    <div className={classNames(styles.page__footer, {
                        [styles.page__footer_checkout]: isCheckoutOpen
                    })}>
                        <Footer columnStyle={isCheckoutOpen}/>
                    </div>
                </div>
                <div className={classNames(styles.wrapper__right, {
                    [styles.wrapper__right_open]: cartIsOpen || isCheckoutOpen,
                    [styles.wrapper__right_hide]: !cartIsShow,
                    [styles.wrapper__right_checkout]: isCheckoutOpen
                })}>
                    <ShoppingCart onSwitchClick={() => {
                        handleCartSwitch()
                    }}
                                  onCheckoutClick={() => {
                                      handleCheckout()
                                  }}
                                  onReturnStockClick={() => {
                                  }}
                                  onPayClick={() => {
                                      handlePay()
                                  }}
                                  isMobileOpen={isMobileCartOpen}
                                  stateOpen={cartIsOpen}
                                  onCheckoutClose={() => setIsMobileCheckoutOpen(false)}
                                  onMobileOpen={() => setIsMobileCartOpen(!isMobileCartOpen)}
                                  onHide={() => handleHideCart()}
                                  onShow={() => {
                                      handleShowCart()
                                  }}
                                  isCheckoutMobileOpen={isMobileCheckoutOpen}
                                  shipPrice={shipPrice}
                                  onCartItemsChange={(ids) => setItemsIds(ids)}
                                  buyStage={currentBuyStage}
                                  onSubTotalChange={(amount) => setAmount(amount)}
                    />
                </div>
                <div className={classNames(styles.wrapper__checkoutWrapper, {
                    [styles.wrapper__checkoutWrapper_checkout]: isCheckoutOpen
                })}>
                    <CheckoutForm
                        onMobilePayClick={() => handlePay()}
                        orderAmount={amount}
                        isMobileCheckoutOpen={isMobileCheckoutOpen}
                        isMobileCartOpen={isMobileCartOpen}
                        onMobileCheckoutOpen={() => {
                            setIsMobileCheckoutOpen(true)
                        }}
                        onShipSelect={(type, price) => {
                            setShipPrice(price)
                        }}
                        onChange={(form) => {
                            setFormItem(form)
                        }}
                    />
                </div>
            </div>
            {/*{*/}
            {/*    isLoad && <Loader/>*/}
            {/*}*/}
        </div>
    )
}