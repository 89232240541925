import networkService from "../../networkService";

export const initCheckoutSession = (products_ids: number[], order_id: string) => {
    return new Promise((resolve, reject) => {
        networkService.post('orders/create-checkout-session/', {products_ids: products_ids, order_id: order_id})
            .then((r) => resolve(r))
            .catch((e) => reject(e))
    })
}

interface IOrderItem {
    email: string,
    first_name: string,
    last_name: string,
    company: string,
    amount: string,
    address: string,
    suite: string,
    city: string,
    postal_code: string,
    country: string,
    phone: string,
    delivery_type: string,
    products_ids: string[]
}


export const createOrder = (order_item: IOrderItem) => {
    return new Promise((resolve, reject) => {
        networkService.post('orders/create-order/', order_item)
            .then((r) => resolve(r))
            .catch((e) => reject(e))
    })
}


export const submitOrder = (stripe_id: string) => {
    return new Promise((resolve, reject) => {
        networkService.post('orders/submit-order/', {stripe_id: stripe_id})
            .then((r) => resolve(r))
            .catch((e) => reject(e))
    })
}
