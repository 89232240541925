import React, {useEffect, useState} from "react";
import styles from "./index.module.scss"
import arrowImg from "./assets/down-chevron.png"
import classNames from "classnames";
import {useLocation, useNavigate} from "react-router-dom";
import {CrossIcon} from "../icons/CrossIcon";
import store from "../../redux/store";
import {IShopItem, removeItem} from "../../redux/slices/cart";
import {useSelector} from "react-redux";
import {ArrowIcon} from "../icons/ArrowIcon";

interface IShoppingCart {
    onSwitchClick: () => void
    onCheckoutClick: () => void
    onReturnStockClick: () => void
    onPayClick: () => void
    onSubTotalChange: (total: number) => void
    buyStage: BuyStage
    onCartItemsChange: (items_ids: any) => void
    stateOpen: boolean
    shipPrice: number
    isCheckoutMobileOpen?: boolean
    isMobileOpen?: boolean
    onMobileOpen: () => void
    onCheckoutClose: () => void
    onHide: () => void
    onShow: () => void
}

export enum BuyStage {
    default = 'default',
    checkout = 'checkout',
    payment = 'payment'
}

export const ShoppingCart: React.FC<IShoppingCart> = (props) => {
    const namesReducer = useSelector((state: any) => state.cart)
    const {items} = namesReducer
    const [subTotal, setSubTotal] = useState(0)
    const [isShow, setIsShow] = useState(false)
    const [removedItem, setRemovedItem] = useState('')
    const navigate = useNavigate()
    const location = useLocation()

    console.log(location)

    const {
        buyStage, shipPrice, stateOpen, onSwitchClick,
        onReturnStockClick, onCheckoutClick, onPayClick,
        onSubTotalChange, onCartItemsChange, isCheckoutMobileOpen, isMobileOpen, onMobileOpen,
        onCheckoutClose, onHide, onShow
    } = props

    const handleReturnStockClick = () => {
        if (location.pathname.includes('/shop-item/')) navigate('/stock')
        onReturnStockClick()
    }

    const handleRemoveFromCart = (item: IShopItem) => {
        setRemovedItem(item.product_id)
        setTimeout(() => {
            store.dispatch(removeItem(item))
            setRemovedItem('')
        }, 2100)
    }

    const handleMobileOpen = () => {
        onMobileOpen()
    }

    const handleCheckoutClose = () => {
         onCheckoutClose()
    }

    useEffect(() => {
        let subTotalInit = 0
        let ids = []
        items.map((item) => {
            subTotalInit = subTotalInit + parseInt(item.price_number)
            ids.push(item.product_id)
        })
        setSubTotal(subTotalInit)
        onCartItemsChange(ids)
        if (ids.length > 0) {
            setIsShow(true)
            onShow()
        }
        else {
            setIsShow(false)
            onHide()
        }
    }, [items])

    useEffect(() => {
        if (items.length < 1 && buyStage === BuyStage.checkout ) navigate('/stock')
    }, [items])

    useEffect(() => {
        onSubTotalChange(subTotal + shipPrice)
    }, [subTotal, shipPrice])

    const listItems = items.map((item, index) =>
        <div key={item.title + index}
             className={classNames(styles.cart__item, {
                 [styles.cart__item_deleted]: item.product_id === removedItem
             })}>
            <div
                onClick={() => handleRemoveFromCart(item)}
                className={styles.cart__itemHeader}>
                <CrossIcon className={styles.cart__crossIcon}/>
            </div>
            <div className={styles.cart__itemBody}>
                <div className={styles.cart__imageGroup}>
                    <div className={styles.cart__itemImg}>
                        <img src={process.env.REACT_APP_ENV_BACKEND_DOMAIN + item.preview_img}/>
                    </div>
                    <div className={styles.cart__itemTitle}>{item.title}</div>
                </div>
                <div className={styles.cart__itemPrice}>{item.price}</div>
            </div>
        </div>
    );

    return (
        <div className={classNames(styles.cart, {
            [styles.cart__switch_hidden]: false,
            [styles.cart_isCheckoutMobileOpen]: isCheckoutMobileOpen,
            [styles.cart_isMobileOpen]: isMobileOpen,
            [styles.tiltInBr]: isShow,
            [styles.tiltOutBr]: !isShow,
            [styles.cart_inner]: location.pathname.includes('shop-item') || location.pathname.includes('checkout')
        })}>
            <div onClick={() => onSwitchClick()} className={classNames(styles.cart__switch, {
                [styles.cart__switch_open]: stateOpen
            })}>
                <ArrowIcon width={18} height={18}/>
            </div>
            {isCheckoutMobileOpen &&
                <div
                    onClick={() => handleCheckoutClose()}
                    className={styles.cart__cartMobileArrow}>
                   <ArrowIcon width={15} height={15}/>
                </div>
            }
            {!isCheckoutMobileOpen &&
                <div className={styles.cart__title}
                     onClick={() => handleMobileOpen()}
                >
                    cart
                </div>
            }
            <div className={styles.cart__wrapper}>
                <div className={styles.cart__itemsBody}>
                    {listItems}
                </div>
                <div className={styles.footer}>
                    <div className={styles.footer__textGroup}>
                        <div>
                            <p>subtotal<span>€{subTotal} incl. vat</span></p>
                        </div>
                        <div>
                            <p>shipping<span>€{shipPrice}</span></p>
                        </div>
                    </div>
                    <div>
                        {(buyStage == BuyStage.default || buyStage == BuyStage.checkout) &&
                            <div className={styles.footer__button}
                                 onClick={() => handleReturnStockClick()}
                            >
                                return to stock
                            </div>
                        }
                        <div className={classNames([styles.footer__button, styles.footer__button_blue])}
                             onClick={() => onCheckoutClick()}
                        >
                            {buyStage == BuyStage.default && <div>checkout</div>}
                            {buyStage == BuyStage.checkout &&
                                <div onClick={() => onPayClick()}>
                                    Pay €{subTotal + shipPrice}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}