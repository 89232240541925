import React, {useEffect, useState} from "react";
import {ShopItem} from "../../components/shop_item";
import styles from "./index.module.scss"
import {Logo} from "../../components/logo";
import {Footer} from "../../components/footer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {BuyStage, ShoppingCart} from "../../components/shopping_cart";
import classNames from "classnames";
import {getAllShopItems} from "../../services/api/shop_items";
import item_1_img from "../../assets/catalog/item_1.png"
import item_2_img from "../../assets/catalog/item_2.png"
import item_3_img from "../../assets/catalog/item_3.png"
import item_4_img from "../../assets/catalog/item_4.png"
import item_5_img from "../../assets/catalog/item_5.png"
import item_6_img from "../../assets/catalog/item_6.png"
import item_7_img from "../../assets/catalog/item_7.png"
import item_8_img from "../../assets/catalog/item_8.png"
import item_9_img from "../../assets/catalog/item_9.png"
import item_10_img from "../../assets/catalog/item_10.png"
import item_11_img from "../../assets/catalog/item_11.png"
import item_12_img from "../../assets/catalog/item_12.png"
import item_13_img from "../../assets/catalog/item_13.png"
import item_14_img from "../../assets/catalog/item_14.png"
import item_15_img from "../../assets/catalog/item_15.png"
import item_16_img from "../../assets/catalog/item_16.png"
import item_17_img from "../../assets/catalog/item_17.png"
import item_18_img from "../../assets/catalog/item_18.png"
import item_19_img from "../../assets/catalog/item_19.png"
import item_20_img from "../../assets/catalog/item_20.png"
import item_21_img from "../../assets/catalog/item_21.png"
import item_22_img from "../../assets/catalog/item_22.png"
import item_23_img from "../../assets/catalog/item_23.png"
import item_24_img from "../../assets/catalog/item_24.png"
import item_25_img from "../../assets/catalog/item_25.png"
import item_26_img from "../../assets/catalog/item_26.png"
import item_27_img from "../../assets/catalog/item_27.png"
import item_27_mob from "../../assets/catalog/item_27_mob.png"
import item_28_img from "../../assets/catalog/item_28.png"
import item_29_img from "../../assets/catalog/item_29.png"
import item_30_img from "../../assets/catalog/item_30.png"
import item_31_img from "../../assets/catalog/item_31.png"
import item_32_img from "../../assets/catalog/item_32.png"
import item_33_img from "../../assets/catalog/item_33.png"
import item_34_img from "../../assets/catalog/item_34.png"
import item_35_img from "../../assets/catalog/item_35.png"
import item_36_img from "../../assets/catalog/item_36.png"
import item_37_img from "../../assets/catalog/item_37.png"
import item_38_img from "../../assets/catalog/item_38.png"
import item_39_img from "../../assets/catalog/item_39.png"
import item_40_img from "../../assets/catalog/item_40.png"
import item_41_img from "../../assets/catalog/item_41.png"
import item_42_img from "../../assets/catalog/item_42.png"
import item_43_img from "../../assets/catalog/item_43.png"
import item_44_img from "../../assets/catalog/item_44.png"
import item_45_img from "../../assets/catalog/item_45.png"
import item_46_img from "../../assets/catalog/item_46.png"
import item_47_img from "../../assets/catalog/item_47.png"
import item_48_img from "../../assets/catalog/item_48.png"
import item_49_img from "../../assets/catalog/item_49.png"
import item_50_img from "../../assets/catalog/item_50.png"
import item_51_img from "../../assets/catalog/item_51.png"
import item_52_img from "../../assets/catalog/item_52.png"
import item_53_img from "../../assets/catalog/item_53.png"
import item_54_img from "../../assets/catalog/item_54.png"
import item_55_img from "../../assets/catalog/item_55.png"
import item_56_img from "../../assets/catalog/item_56.png"
import item_57_img from "../../assets/catalog/item_57.png"
import item_58_img from "../../assets/catalog/item_58.png"
import {submitOrder} from "../../services/api/orders";


export const CatalogPage: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const {stripe_id} = useParams()
    const [cartIsOpen, setCartIsOpen] = useState(false)
    const [shopItems, setShopItems] = useState<any>([])
    const [isLoad, setIsLoad] = useState(true)


    const handleCartSwitch = () => {
        setCartIsOpen(!cartIsOpen)
    }

    useEffect(() => {
        if (shopItems.length < 1) {
            getAllShopItems()
                .then((r: any) => setShopItems(r.items))
                .catch((e) => console.log(e))
        }
    }, [shopItems])


    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoad(false)
        }, 1000);
        return () => clearTimeout(timer);
    })

    const handleRouteItem = (product_id: string) => {
        navigate('/shop-item/' + product_id, {state: {prevPath: 'catalog'}})
    }

    useEffect(() => {
        if (stripe_id) {
            submitOrder(stripe_id)
                .then((r) => navigate('/stock'))
                .catch((e) => console.log(e))
        }
    }, [stripe_id])

    useEffect(() => {
        if (location.pathname === '/cancelled'){
            navigate('/stock')
        }
    }, [location])

    return (
        <div>
            <div className={styles.page}>
                <div onClick={() => {
                    navigate('/')
                }}>
                    <Logo/>
                </div>
                <div className={styles.catalog}>
                    <div className={classNames(styles.catalog__row)}>
                        <div onClick={() => {
                            handleRouteItem('1')
                        }} className={styles.catalog__item1}>
                            <img src={item_1_img}/>
                            <div className={styles.catalog__text}>
                                zuzanna mazurek <br/>
                                untitled, 2020.
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('2')
                        }}
                             className={styles.catalog__item2}>
                            <img src={item_2_img}/>
                            <div className={styles.catalog__text}>
                                magdalena abakanowicz <br/>
                                rzeżba
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('3')
                        }}
                             className={styles.catalog__item3}>
                            <img src={item_3_img}/>
                            <div className={styles.catalog__text}>
                                rimowa elongated case <br/>
                                200€
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.catalog__row, styles.catalog__row_end)}>
                        <div className={styles.catalog__item4}
                             onClick={() => {
                                 handleRouteItem('4')
                             }}
                        >
                            <img src={item_4_img}/>
                            <div className={styles.catalog__text}>
                                hermes kelly 32’’<br/>
                                handbag
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('5')
                        }}
                             className={styles.catalog__item5}>
                            <img src={item_5_img}/>
                            <div className={styles.catalog__text}>
                                “fight club” screenplay, signed.<br/>
                                500€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('6')
                        }}
                             className={styles.catalog__item6}>
                            <img src={item_6_img}/>
                            <div className={styles.catalog__text}>
                                viktar aberamok<br/>
                                portret pamięciowy 9/9, 2022.
                            </div>
                        </div>
                    </div>
                    <div onClick={() => {
                        handleRouteItem('3')
                    }}
                         className={styles.catalog_item3mob}>
                        <img src={item_3_img}/>
                        <div className={styles.catalog__text}>
                            rimowa elongated case <br/>
                            200€
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('7')
                        }}
                             className={styles.catalog__item7}>
                            <img src={item_7_img}/>
                            <div className={styles.catalog__text}>
                                signet,nina ornatkiewicz<br/>
                                420€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('8')
                        }}
                             className={styles.catalog__item8}>
                            <img src={item_8_img}/>
                            <div className={styles.catalog__text}>
                                leather gloves<br/>
                                100€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('9')
                        }}
                             className={styles.catalog__item9}>
                            <img src={item_9_img}/>
                            <div className={styles.catalog__text}>
                                beocom 1400 telephone<br/>
                                120€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('10')
                        }}
                             className={styles.catalog__item10}>
                            <img src={item_10_img}/>
                            <div className={styles.catalog__text}>
                                eight leather chairs, individually or as a set.<br/>
                                100-700.
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('11')
                        }}
                             className={styles.catalog__item11}>
                            <img src={item_11_img}/>
                            <div className={styles.catalog__text}>
                                Fine 19th century icon<br/>
                                1200€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('12')
                        }}
                             className={styles.catalog__item12}>
                            <img src={item_12_img}/>
                            <div className={styles.catalog__text}>
                                a night at the playboy mansion - cd<br/>
                                15€
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.catalog__row, styles.catalog__row_mob)}>
                        <div onClick={() => {
                            handleRouteItem('56')
                        }}
                             className={styles.catalog__item56}>
                            <img src={item_56_img}/>
                            <div className={styles.catalog__text}>
                                long coat <br/>300€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('57')
                        }}
                             className={styles.catalog__item57}>
                            <img src={item_57_img}/>
                            <div className={styles.catalog__text}>
                                long dress <br/>250€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('58')
                        }}
                             className={styles.catalog__item58}>
                            <img src={item_58_img}/>
                            <div className={styles.catalog__text}>
                                yves saint laurent suit<br/>
                                150€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('13')
                        }}
                             className={styles.catalog__item13}>
                            <img src={item_13_img}/>
                            <div className={styles.catalog__text}>
                                jakub lyszczarz<br/>
                                untitled, 2023.
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('14')
                        }} className={styles.catalog__item14}>
                            <img src={item_14_img}/>
                            <div className={styles.catalog__text}>
                                yves saint laurent suit<br/>
                                150€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('15')
                        }}
                             className={styles.catalog__item15}>
                            <img src={item_15_img}/>
                            <div className={styles.catalog__text}>
                                chevrolet corvette c3, 1979
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.catalog__row, styles.catalog__row_mob)}>
                        <div onClick={() => {
                            handleRouteItem('17')
                        }}
                             className={styles.catalog__item17}>
                            <img src={item_17_img}/>
                            <div className={styles.catalog__text}>
                                biuro’s spectacular bar of soap<br/>
                                9€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('19')
                        }}
                             className={styles.catalog__item19}>
                            <img src={item_19_img}/>
                            <div className={styles.catalog__text}>
                                bezimienny<br/>
                                women strike in khorinis, 2021.
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('20')
                        }}
                             className={styles.catalog__item20}>
                            <img src={item_20_img}/>
                            <div className={styles.catalog__text}>
                                chanel paddle gloves<br/>
                                1000€
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.catalog__row, styles.catalog__row_mob)}>
                        <div onClick={() => {
                            handleRouteItem('21')
                        }}
                             className={styles.catalog__item21}>
                            <img src={item_21_img}/>
                            <div className={styles.catalog__text}>
                                aleksandra nowicka<br/>
                                bez tytuły, 2021.
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('22')
                        }}
                             className={styles.catalog__item22}>
                            <img src={item_22_img}/>
                            <div className={styles.catalog__text}>
                                vodka/liqueur set<br/>
                                150€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('27')
                        }}
                             className={styles.catalog__item27mob}>
                            <img src={item_27_mob}/>
                            <div className={styles.catalog__text}>
                                A set of four destroyed chairs.<br/>
                                300€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('24')
                        }}
                             className={styles.catalog__item24}>
                            <img src={item_24_img}/>
                            <div className={styles.catalog__text}>
                                y/project canvas boots<br/>
                                850€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('25')
                        }}
                             className={styles.catalog__item25}>
                            <img src={item_25_img}/>
                            <div className={styles.catalog__text}>
                                martyna pinkowska<br/>
                                najniższy stopień trzeźwości rozumu, 2021.
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('26')
                        }}
                             className={styles.catalog__item26}>
                            <img src={item_26_img}/>
                            <div className={styles.catalog__text}>
                                ‘casino’ playing cards<br/>
                                25€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('27')
                        }} className={styles.catalog__item27}>
                            <img src={item_27_img}/>
                            <div className={styles.catalog__text}>
                                A set of four destroyed chairs.<br/>
                                300€
                            </div>
                        </div>
                    </div>
                    <div
                        className={classNames(styles.catalog__row, styles.catalog__row_mob)}>
                        <div onClick={() => {
                            handleRouteItem('36')
                        }}
                             className={styles.catalog__item36mob}>
                            <img src={item_36_img}/>
                            <div className={styles.catalog__text}>
                                crystal ashtray<br/>
                                100€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('28')
                        }} className={styles.catalog__item28}>
                            <img src={item_28_img}/>
                            <div className={styles.catalog__text}>
                                helena minginowicz<br/>
                                the kiss-sweaty hand, 2022.
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('29')
                        }}
                             className={styles.catalog__item29}>
                            <img src={item_29_img}/>
                            <div className={styles.catalog__text}>
                                after the playboy mansion- cd<br/>
                                20€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('30')
                        }}
                             className={styles.catalog__item30}>
                            <img src={item_30_img}/>
                            <div className={styles.catalog__text}>
                                perpetual calendar<br/>
                                40€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('31')
                        }}
                             className={styles.catalog__item31}>
                            <img src={item_31_img}/>
                            <div className={styles.catalog__text}>
                                Slawomir Pawszak<br/>
                                TBC,TBC.
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('32')
                        }}
                             className={styles.catalog__item32}>
                            <img src={item_32_img}/>
                            <div className={styles.catalog__text}>
                                set of five matchbooks<br/>
                                15€
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.catalog__row, styles.catalog__row_mob)}>
                        <div onClick={() => {
                            handleRouteItem('33')
                        }}
                             className={styles.catalog__item33}>
                            <img src={item_33_img}/>
                            <div className={styles.catalog__text}>
                                helena minginowicz<br/>
                                cocoon (reverse) - goldilocks adventure, 2022.
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('34')
                        }}
                             className={styles.catalog__item34}>
                            <img src={item_34_img}/>
                            <div className={styles.catalog__text}>
                                sudoku pocket game<br/>
                                40€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('35')
                        }}
                             className={styles.catalog__item35}>
                            <img src={item_35_img}/>
                            <div className={styles.catalog__text}>
                                gucci money clip<br/>
                                350€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('36')
                        }}
                             className={styles.catalog__item36}>
                            <img src={item_36_img}/>
                            <div className={styles.catalog__text}>
                                crystal ashtray<br/>
                                100€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('37')
                        }}
                             className={styles.catalog__item37}>
                            <img src={item_37_img}/>
                            <div className={styles.catalog__text}>
                                alexander sovtysik<br/>
                                hydra2, 2022.
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.catalog__row, styles.catalog__row_mob)}>
                        <div onClick={() => {
                            handleRouteItem('53')
                        }}
                             className={styles.catalog__item53mob}>
                            <img src={item_53_img}/>
                            <div className={styles.catalog__text}>
                                silver tea cup<br/>
                                120€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('38')
                        }}
                             className={styles.catalog__item38}>
                            <img src={item_38_img}/>
                            <div className={styles.catalog__text}>
                                artisan catering by basia bieniek.<br/>
                                starting at 150€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('39')
                        }}
                             className={styles.catalog__item39}>
                            <img src={item_39_img}/>
                            <div className={styles.catalog__text}>
                                jan albert cieślak<br/>
                                bez tytułu, 2016.
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('54')
                        }}
                             className={styles.catalog__item54mob}>
                            <img src={item_54_img}/>
                            <div className={styles.catalog__text}>
                                jan albert cieślak<br/>
                                ‘v/pocd-2’, 2021.
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('40')
                        }}
                             className={styles.catalog__item40}>
                            <img src={item_40_img}/>
                            <div className={styles.catalog__text}>
                                two triangular small wooden, probably asian tables<br/>
                                100€
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.catalog__row, styles.catalog__row_mob)}>
                        <div onClick={() => {
                            handleRouteItem('41')
                        }}
                             className={styles.catalog__item41}>
                            <img src={item_41_img}/>
                            <div className={styles.catalog__text}>
                                zuzanna mazurek<br/>
                                “table”, 2020.
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('48')
                        }}
                             className={styles.catalog__item48mob}>
                            <img src={item_48_img}/>
                            <div className={styles.catalog__text}>
                                nike libretto trainers<br/>
                                150€
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.catalog__row, styles.catalog__row_end)}>
                        <div onClick={() => {
                            handleRouteItem('42')
                        }}
                             className={styles.catalog__item42}>
                            <img src={item_42_img}/>
                            <div className={styles.catalog__text}>
                                costum lv bag<br/>
                                1200€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('14')
                        }} className={styles.catalog__item14mob}>
                            <img src={item_14_img}/>
                            <div className={styles.catalog__text}>
                                yves saint laurent suit<br/>
                                150€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('44')
                        }}
                             className={styles.catalog__item44}>
                            <img src={item_44_img}/>
                            <div className={styles.catalog__text}>
                                ellonged hammered bundle, natalia samonek<br/>
                                tbc€
                            </div>
                        </div>
                    </div>
                    <div onClick={() => {
                        handleRouteItem('42')
                    }}
                         className={styles.catalog__item42mob}>
                        <img src={item_42_img}/>
                        <div className={styles.catalog__text}>
                            costum lv bag<br/>
                            1200€
                        </div>
                    </div>
                    <div onClick={() => {
                        handleRouteItem('50')
                    }}
                         className={styles.catalog__item50mob}>
                        <img src={item_50_img}/>
                        <div className={styles.catalog__text}>
                            christ ring, alx.<br/>
                            135€
                        </div>
                    </div>
                    <div className={classNames(styles.catalog__row, styles.catalog__row_mob)}>
                        <div onClick={() => {
                            handleRouteItem('45')
                        }}
                             className={styles.catalog__item45}>
                            <img src={item_45_img}/>
                            <div className={styles.catalog__text}>
                                Fuck-everything t-shirt<br/>
                                40€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('46')
                        }}
                             className={styles.catalog__item46}>
                            <img src={item_46_img}/>
                            <div className={styles.catalog__text}>
                                rimowa elongated case<br/>
                                150€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('52')
                        }}
                             className={styles.catalog__item52mob}>
                            <img src={item_52_img}/>
                            <div className={styles.catalog__text}>
                                oversized adidas bag<br/>
                                250€
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('44')
                        }}
                             className={styles.catalog__item44mob}>
                            <img src={item_44_img}/>
                            <div className={styles.catalog__text}>
                                ellonged hammered bundle, natalia samonek<br/>
                                tbc€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('48')
                        }}
                             className={styles.catalog__item48}>
                            <img src={item_48_img}/>
                            <div className={styles.catalog__text}>
                                nike libretto trainers<br/>
                                150€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('49')
                        }}
                             className={styles.catalog__item49}>
                            <img src={item_49_img}/>
                            <div className={styles.catalog__text}>
                                zuzanna bartoszek<br/>
                                an angel and a woman pretending to faint, 2020.
                            </div>
                        </div>
                        <div onClick={() => {
                            handleRouteItem('50')
                        }}
                             className={styles.catalog__item50}>
                            <img src={item_50_img}/>
                            <div className={styles.catalog__text}>
                                christ ring, alx.<br/>
                                135€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('51')
                        }}
                             className={styles.catalog__item51}>
                            <img src={item_51_img}/>
                            <div className={styles.catalog__text}>
                                letter knives<br/>
                                85€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('52')
                        }}
                             className={styles.catalog__item52}>
                            <img src={item_52_img}/>
                            <div className={styles.catalog__text}>
                                oversized adidas bag<br/>
                                250€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('53')
                        }}
                             className={styles.catalog__item53}>
                            <img src={item_53_img}/>
                            <div className={styles.catalog__text}>
                                silver tea cup<br/>
                                120€
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('54')
                        }}
                             className={styles.catalog__item54}>
                            <img src={item_54_img}/>
                            <div className={styles.catalog__text}>
                                jan albert cieślak<br/>
                                ‘v/pocd-2’, 2021.
                            </div>
                        </div>
                    </div>
                    <div className={styles.catalog__row}>
                        <div onClick={() => {
                            handleRouteItem('55')
                        }}
                             className={styles.catalog__item55}>
                            <img src={item_55_img}/>
                            <div className={styles.catalog__text}>
                                beosound 3000cd/tunner<br/>
                                500€
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classNames(styles.cartWrapper, {
                [styles.cartWrapper_open]: cartIsOpen
            })}>
                <ShoppingCart onSwitchClick={() => {
                    handleCartSwitch()
                }}
                              onCheckoutClick={() => {
                                  navigate('/checkout')
                              }}
                              stateOpen={cartIsOpen}
                              onReturnStockClick={() => setCartIsOpen(false)}
                              shipPrice={0}
                              onPayClick={() => {
                              }}
                              onCartItemsChange={() => {
                              }}
                              onSubTotalChange={() => {
                              }}
                              onMobileOpen={() => {
                              }}
                              onHide={() => {
                              }}
                              onShow={() => {
                              }}
                              onCheckoutClose={() => {
                              }}
                              buyStage={BuyStage.default}/>
            </div>
            <div className={styles.page__footer}>
                <Footer/>
            </div>
            {/*{*/}
            {/*    isLoad && <Loader/>*/}
            {/*}*/}
        </div>
    )
}