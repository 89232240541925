import React, {useEffect, useState} from "react";
import styles from "./index.module.scss";
import {ShopItemCarousel} from "../shop_item_carousel";
import store from "../../redux/store";
import {addItem} from "../../redux/slices/cart";
import classNames from "classnames";
import parse from 'html-react-parser'
import {useSelector} from "react-redux";

interface IShopItemDetails {
    animateCheckout: boolean
    animateCart: boolean
    item: any
}

export const ShopItemDetails: React.FC<IShopItemDetails> = (props) => {
    const {animateCheckout, animateCart, item} = props
    const namesReducer = useSelector((state: any) => state.cart)
    const {items} = namesReducer
    const [isAddedToCart, setIsAddedToCart] = useState(false)
    const [addToCartClicked, setAddToCartClicked] = useState(false)

    const handleAddToCart = () => {
        setAddToCartClicked(true)
        if (!isAddedToCart) {
            store.dispatch(addItem({
                title: item.title,
                preview_img: item.preview_image,
                price: '€' + item.price,
                price_number: item.price,
                product_id: item.product_id,
                stripe_id: item.stripe_id
            }))
        }
    }

    useEffect(() => {
        const arr = [...items]

        if (arr.filter(e => e.product_id === item.product_id).length > 0) {
            setIsAddedToCart(true)
        } else setIsAddedToCart(false)

    }, [items])

    useEffect(() => {
        console.log(isAddedToCart)
    }, [isAddedToCart])

    return (
        <div className={classNames(styles.itemContainer, [
            {
                [styles.itemContainer_cart]: animateCart
            },
            {
                [styles.itemContainer_checkout]: animateCheckout
            }
        ])}>
            <div className={styles.itemCarouselContainer}>
                <ShopItemCarousel items={item.images}/>
            </div>
            <div className={styles.textContainer}>
                <div>
                    {parse(item.description)}
                </div>
                {item.price &&
                    <div
                        onClick={() => handleAddToCart()}
                        className={classNames(styles.button, {
                            [styles.button_disable]: isAddedToCart
                        })}>
                        {!isAddedToCart &&
                            <div className={classNames("", {
                                [styles.button__on]: !isAddedToCart && addToCartClicked,
                            })}>
                                add to cart
                            </div>
                        }
                        {isAddedToCart &&
                            <div className={classNames("", {
                                [styles.button__off]: isAddedToCart
                            })}>
                                added
                            </div>
                        }
                    </div>
                }
                {!item.price &&
                    <div className={styles.button}>
                        <a href={'mailto:client@biuro.store?subject=I want ' + item.title}>
                            enquire
                        </a>
                    </div>
                }
            </div>
        </div>
    )
}