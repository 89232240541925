// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input_input__bSVm8 {\n  width: 100%;\n}\n.input_input__label__aWz0s {\n  font-size: 12px;\n  color: #0000E4;\n  margin-bottom: 8px;\n}\n@media screen and (max-width: 500px) {\n  .input_input__label__aWz0s {\n    display: none;\n  }\n}\n.input_input__bSVm8 input {\n  border: 1px solid #0000E4;\n  padding: 5px 10px;\n  outline: none;\n  user-focus: none;\n  width: 100%;\n  box-sizing: border-box;\n  font-size: 14px;\n  color: #0000E4;\n}\n.input_input__bSVm8 input::placeholder {\n  opacity: 0;\n  font-size: 12px;\n  color: #0000E4;\n}\n@media screen and (max-width: 500px) {\n  .input_input__bSVm8 input::placeholder {\n    opacity: 1;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/input/index.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;AADF;AAGE;EACE,eAAA;EACA,cAAA;EACA,kBAAA;AADJ;AAEI;EAJF;IAKI,aAAA;EACJ;AACF;AAEE;EACE,yBAAA;EACA,iBAAA;EACA,aAAA;EACA,gBAAA;EACA,WAAA;EACA,sBAAA;EACA,eAAA;EACA,cAAA;AAAJ;AAEI;EACE,UAAA;EACA,eAAA;EACA,cAAA;AAAN;AACM;EAJF;IAKI,UAAA;EAEN;AACF","sourcesContent":["$small: 500px;\n\n.input {\n  width: 100%;\n\n  &__label {\n    font-size: 12px;\n    color: #0000E4;\n    margin-bottom: 8px;\n    @media screen and (max-width: $small) {\n      display: none;\n    }\n  }\n\n  input {\n    border: 1px solid #0000E4;\n    padding: 5px 10px;\n    outline: none;\n    user-focus: none;\n    width: 100%;\n    box-sizing: border-box;\n    font-size: 14px;\n    color: #0000E4;\n\n    &::placeholder {\n      opacity: 0;\n      font-size: 12px;\n      color: #0000E4;\n      @media screen and (max-width: $small) {\n        opacity: 1;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input_input__bSVm8",
	"input__label": "input_input__label__aWz0s"
};
export default ___CSS_LOADER_EXPORT___;
