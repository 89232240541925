import React from "react";
import logoIcon from "./assets/biuro_logo.png"
import styles from "./index.module.scss"

interface ILogo{
    onClick?: () => void
}

export const Logo: React.FC<ILogo> = (props) => {
    const {onClick} = props
    return (
        <div onClick={() => {onClick()}} className={styles.logo}>
            <img src={logoIcon} alt={'logo'}/>
        </div>
    )
}