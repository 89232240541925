// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo_logo__GbPE3 {\n  position: fixed;\n  left: 7.5vw;\n  top: 9vw;\n  cursor: pointer;\n  z-index: 9999;\n}\n@media screen and (max-width: 500px) {\n  .logo_logo__GbPE3 {\n    left: 10.2vw;\n  }\n}\n.logo_logo__GbPE3 img {\n  width: 55px;\n}", "",{"version":3,"sources":["webpack://./src/components/logo/index.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EAEA,WAAA;EACA,QAAA;EACA,eAAA;EACA,aAAA;AAFF;AAIE;EARF;IASI,YAAA;EADF;AACF;AAGE;EACE,WAAA;AADJ","sourcesContent":["$small: 500px;\n\n.logo {\n  position: fixed;\n\n  left: 7.5vw;\n  top: 9vw;\n  cursor: pointer;\n  z-index: 9999;\n\n  @media screen and (max-width: $small) {\n    left: 10.2vw;\n  }\n\n  img {\n    width: 55px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "logo_logo__GbPE3"
};
export default ___CSS_LOADER_EXPORT___;
