import "styles/global.scss";
import {Provider} from "react-redux";
import store from "redux/store";
import {RoutesMap} from "./routes";
import { BrowserRouter } from 'react-router-dom';

function App(): JSX.Element {
    return (
        <Provider store={store}>
            <div className="App">
                <BrowserRouter>
                    <RoutesMap/>
                </BrowserRouter>
            </div>
        </Provider>
    );
}

export default App;
