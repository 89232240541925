import axios from "axios";

const urlDecorator = (url: string): string => {
    if (!url.startsWith('/')) {
        return `/${url}`
    }
    return url
}

const networkService = {
    apiUrl() {
        return process.env.REACT_APP_ENV_REST_URL
    },
    token() {
         return false
    },
    headers() {
        let headers: any = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
        if (this.token()) headers.Authorization = `Token ${this.token()}`
        return headers
    },
    headersFormData(){
    let headers: any = {
            'content-Type': 'multipart/form-data'
        }
        if (this.token()) headers.Authorization = `Token ${this.token()}`
        return headers
    },
    get(url: string) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${this.apiUrl()}${urlDecorator(url)}`, {headers: this.headers()})
                .then(this.handleStatusCode)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    post(url: string, body: any) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${this.apiUrl()}${urlDecorator(url)}`, body, {headers: this.headers()})
                .then(this.handleStatusCode)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    postFormData(url: string, body: any) {
        return new Promise((resolve, reject) => {
            console.log(body)
            axios
                .post(`${this.apiUrl()}${urlDecorator(url)}`, body, {headers: this.headersFormData()})
                .then(this.handleStatusCode)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
     postBlobData(url: string, body: any) {
        return new Promise((resolve, reject) => {
            console.log(body)
            axios
                .post(`${this.apiUrl()}${urlDecorator(url)}`, body, {headers: this.headers(), responseType: 'blob'})
                .then(this.handleStatusCode)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    put(url: string, body: any) {
        return new Promise((resolve, reject) => {
            axios
                .put(`${this.apiUrl()}${urlDecorator(url)}`, body, {headers: this.headers()})
                .then(this.handleStatusCode)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    delete(url: string) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${this.apiUrl()}${urlDecorator(url)}`, {headers: this.headers()})
                .then(this.handleStatusCode)
                .then((data) => {
                    resolve(data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    handleStatusCode(res: any) {
        switch (res.status) {
            case 400:
                throw new Error('Bad Request')
            case 401:
                throw new Error('Unauthorized')
            case 404:
                throw new Error('Endpoint not found (404)')
            default:
                return res
        }
    },
}

export default networkService