import React, {useEffect, useState} from "react";
import {Input} from "../input";
import styles from "./index.module.scss"
import classNames from "classnames";

enum DeliveryType {
    mail = 'mail',
    courier = 'courier',
    dhl = 'dhl'
}

interface ICheckoutForm {
    onShipSelect: (type: DeliveryType, price: number) => void
    onMobileCheckoutOpen: () => void
    isMobileCartOpen: boolean
    isMobileCheckoutOpen: boolean
    onChange: (order: any) => void
    orderAmount: number
    onMobilePayClick: () => void
}

export const CheckoutForm: React.FC<ICheckoutForm> = (props) => {
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [company, setCompany] = useState('')
    const [address, setAddress] = useState('')
    const [app, setApp] = useState('')
    const [city, setyCity] = useState('')
    const [postalcode, setPostalcode] = useState('')
    const [country, setCountry] = useState('')
    const [phone, setPhone] = useState('')
    const [shipType, setShipType] = useState<DeliveryType | null>(null)

    const {
        onShipSelect, onChange, onMobileCheckoutOpen,
        isMobileCartOpen, isMobileCheckoutOpen, orderAmount, onMobilePayClick
    } = props

    const handleShipSelect = (type: DeliveryType) => {
        setShipType(type)
        let shipPriceInner = 0
        switch (type) {
            case DeliveryType.courier:
                shipPriceInner = 4
                break
            case DeliveryType.dhl:
                shipPriceInner = 6
                break
            case DeliveryType.mail:
                shipPriceInner = 4
                break
        }

        onShipSelect(type, shipPriceInner)
    }

    const handleMobileCheckoutOpen = () => {
        onMobileCheckoutOpen()
    }

    useEffect(() => {
        onChange({
            email: email,
            first_name: firstName,
            last_name: lastName,
            company: company,
            address: address,
            suite: app,
            city: city,
            postal_code: postalcode,
            country: country,
            phone: phone,
            delivery_type: shipType
        })
    }, [email, firstName, lastName, company, address, app, city, postalcode, country, phone, shipType])


    return (
        <div className={classNames(styles.form, {
            [styles.form_open]: isMobileCheckoutOpen,
            [styles.form_hide]: !isMobileCartOpen
        })}>
            <div
                onClick={() => handleMobileCheckoutOpen()}
                className={styles.mobTitle}>
                checkout
            </div>
            <div className={styles.form__emailGroup}>
                <Input onChange={(value) => {
                    setEmail(value)
                }}
                       label={'e-mail'} value={email}
                       placeholder={'e-mail'}
                />
                <div className={styles.form__newsletter}>
                    <input className={styles.styledCheckbox} id="styled-checkbox-1" type="checkbox"/>
                    <label htmlFor="styled-checkbox-1">subscribe to newsletter</label>
                </div>
            </div>
            <div className={styles.form__group}>
                <div className={styles.form__item}>
                    <Input onChange={(value) => {
                        setFirstName(value)
                    }}
                           label={'first name'}
                           placeholder={'first name'}
                           value={firstName}/>
                </div>
                <div className={styles.form__item}>
                    <Input onChange={(value) => {
                        setLastName(value)
                    }}
                           label={'last name'}
                           placeholder={'last name'}
                           value={lastName}/>
                </div>
            </div>
            <div className={styles.form__group}>
                <Input onChange={(value) => {
                    setCompany(value)
                }}
                       label={'company'}
                       placeholder={'company'}
                       value={company}/>
            </div>
            <div className={styles.form__group}>
                <div className={styles.form__item}>
                    <Input onChange={(value) => {
                        setAddress(value)
                    }}
                           label={'address'}
                           placeholder={'address'}
                           value={address}/>
                </div>
                <div className={styles.form__item}>
                    <Input onChange={(value) => {
                        setApp(value)
                    }}
                           label={'app, suite, etc. (optional)'}
                           placeholder={'suite'}
                           value={app}/>
                </div>
            </div>
            <div className={styles.form__group}>
                <div className={styles.form__item}>
                    <Input onChange={(value) => {
                        setyCity(value)
                    }}
                           label={'city'}
                           placeholder={'city'}
                           value={city}/>
                </div>
                <div className={styles.form__item}>
                    <Input onChange={(value) => {
                        setPostalcode(value)
                    }}
                           label={'postal code'}
                           placeholder={'postal code'}
                           value={postalcode}/>
                </div>
            </div>
            <div className={styles.form__group}>
                <div className={styles.form__item}>
                    <Input onChange={(value) => {
                        setCountry(value)
                    }}
                           label={'country'}
                           placeholder={'country'}
                           value={country}/>
                </div>
                <div className={styles.form__item}>
                    <Input onChange={(value) => {
                        setPhone(value)
                    }}
                           label={'phone'}
                           placeholder={'phone'}
                           value={phone}/>
                </div>
            </div>
            <div className={styles.form__footer}>
                <div className={styles.form__footerGroup}>
                    {/*<div className={classNames(styles.form__button, {*/}
                    {/*    [styles.form__button_active]: shipType == DeliveryType.mail*/}
                    {/*})}*/}
                    {/*     onClick={() => handleShipSelect(DeliveryType.mail)}*/}
                    {/*>*/}
                    {/*    paczkomat +€4*/}
                    {/*</div>*/}
                    <div className={classNames(styles.form__button, {
                        [styles.form__button_active]: shipType == DeliveryType.courier
                    })}
                         onClick={() => handleShipSelect(DeliveryType.courier)}
                    >
                        inpost kurier +€4
                    </div>
                    <div className={classNames(styles.form__button, {
                        [styles.form__button_active]: shipType == DeliveryType.dhl
                    })}
                         onClick={() => handleShipSelect(DeliveryType.dhl)}
                    >
                        dhl +€6
                    </div>
                </div>
            </div>
            <div onClick={() => onMobilePayClick()}
                 className={styles.form__payButton}>
                Pay € {orderAmount}
            </div>
        </div>
    )
}